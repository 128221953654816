import { Fragment } from "react";
import styles from "./LibraryGridItems.css";
import Image from "../Image";
import SmartLink, { navigate } from "../SmartLink";
import ListTile from "../ListTile";
import { FormField, Label } from "@churchofjesuschrist/eden-form-parts";
import {
    CollectionStack,
    Funnel,
    Search,
} from "@churchofjesuschrist/eden-icons";
import { H2 } from "../../theming/components/eden-headings";
import TypeAhead from "@churchofjesuschrist/glo-typeahead";
import { useHistory } from "react-router-dom";
import classes from "classnames";

const ICON_MAP = {
    Funnel,
    Search,
};

const iconLookup = (icon = "Funnel") => ICON_MAP[icon];

/* the original library item tile */
const Portrait = ({
    alt = "",
    collectionClassName,
    fallbackEntryImage,
    isCollection,
    title,
    uri,
    src,
    srcset,
}) => {
    return (
        <SmartLink to={uri} className={styles.portrait}>
            <div className={styles.portraitContainer}>
                <Image
                    alt={alt}
                    className={classes(collectionClassName, styles.portraitImg)}
                    height="103.5"
                    isLibraryItem={true}
                    passedStyle={{
                        "--fallbackImage": `url(${fallbackEntryImage})`,
                    }}
                    placeholderClass={styles.placeholder}
                    // sizes to match @media in .portrait in CS. calc divided by number of columns at that range
                    sizes="(max-width: 333px) 75px, (max-width: 599px) calc((90vw - 32px) / 3), (max-width: 959px) calc((90vw - 48px) / 4), (max-width: 1311px) 220px, (min-width: 1312px) 142px, 200px"
                    src={src}
                    srcset={srcset}
                    width="75"
                />
                {isCollection && (
                    <div className={styles.portraitLowerGradient}>
                        <CollectionStack size={"1.5rem"} color="white" />
                    </div>
                )}
            </div>
            <span
                className={styles.portraitTitle}
                dangerouslySetInnerHTML={{ __html: title }}
            />
        </SmartLink>
    );
};

const Tile = ({
    alt = "",
    meta,
    src = "",
    srcset,
    title,
    secondaryMeta,
    uri,
}) => (
    <ListTile
        alignment="start"
        className={styles.tile}
        hideRule={false}
        href={uri}
        image={{
            alt,
            sizes: "(max-width: 333px) 75px, (max-width: 599px) calc((90vw - 32px) / 3), (max-width: 959px) calc((90vw - 48px) / 4), (max-width: 1311px) 220px, (min-width: 1312px) 142px, 200px",
            src,
            srcset,
        }}
        primaryMeta={meta}
        secondaryMeta={secondaryMeta}
        title={title}
    />
);

const SearchOrFilter = ({
    icon,
    items = [],
    history,
    lang,
    placeholder,
    title,
    subtype,
}) => {
    const handleSubmit = (value) => {
        const item = items.find((item) => item.title === value.title);
        navigate(item.uri, lang, history);
        /*
        If this is a filter, in the future we will narrow down the entries being displayed.
        For now we will treat filters like a normal search.
        navigate to the submitted uri
        */
    };

    const Icon = iconLookup(icon);

    return (
        <FormField className={classes(styles.search, styles.spanAllColumns)}>
            {title && <Label>{title}</Label>}
            <TypeAhead
                className={subtype !== "filter" ? styles.constrained : ""}
                items={items}
                onSubmit={handleSubmit}
                placeholder={placeholder}
                title={title}
                Icon={Icon}
                fuseOptions={{ includeMatches: true }}
            />
        </FormField>
    );
};

const LibraryGridItems = ({
    crossLinkMode,
    fallbackEntryImage,
    lang,
    sections,
}) => {
    const history = useHistory();

    return (
        <div className={styles.libraryGridLayout}>
            {sections.map((section) => {
                return (
                    <Fragment key={section.sectionKey}>
                        {section.title && (
                            <H2
                                className={classes(
                                    styles.spanAllColumns,
                                    styles.sectionSpacing
                                )}
                                key={section.title}
                                small
                            >
                                {section.title}
                            </H2>
                        )}
                        {section.entries.map((entry) => {
                            const type = entry.subtype || entry.type;

                            switch (type) {
                                case "item":
                                    // don't show notes in crosslinkmode
                                    if (
                                        crossLinkMode &&
                                        entry.uri.endsWith("/notes")
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <Portrait
                                            {...entry}
                                            fallbackEntryImage={
                                                fallbackEntryImage?.src
                                            }
                                            history={history}
                                            isCollection={false}
                                            key={entry.title}
                                            lang={lang}
                                        />
                                    );
                                case "collection":
                                    return (
                                        <Portrait
                                            {...entry}
                                            collectionClassName={
                                                styles.collectionPortraitImage
                                            }
                                            fallbackEntryImage={
                                                fallbackEntryImage?.src
                                            }
                                            history={history}
                                            isCollection={true}
                                            key={entry.title}
                                            lang={lang}
                                        />
                                    );
                                case "filter":
                                case "search":
                                    return (
                                        <SearchOrFilter
                                            {...entry}
                                            history={history}
                                            key={
                                                entry.title || entry.placeholder
                                            }
                                            lang={lang}
                                        />
                                    );

                                case "secondary":
                                    return (
                                        <Tile
                                            {...entry}
                                            sizes="(max-width: 372px) 75px, (max-width: 465px) 100px, (max-width: 599px) 112px, (max-width: 1167px) 100px, (max-width: 1311px) 112px, 100px"
                                            // Key is determined by the title of the item and the meta (if it exists)
                                            // We had to add the meta part to avoid errors when titles are repeated on a page (such as talks)
                                            key={
                                                entry.meta !== undefined
                                                    ? entry.title.concat(
                                                          " ",
                                                          entry.meta
                                                      )
                                                    : entry.title
                                            }
                                        />
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </Fragment>
                );
            })}
        </div>
    );
};

export default LibraryGridItems;
